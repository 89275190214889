import { Route, Switch } from 'react-router-dom'
import { EmbeddedOmsPage } from 'components/common/EmbeddedOmsPage'

export function SettingsRoutes() {
  const routes = [
    '/settings/store-setup',
    '/settings/shipping',
    '/settings/taxes',
    '/settings/payments',
    '/settings/refunds',
    '/settings/zones',
  ]

  return (
    <Switch>
      {routes.map(path => (
        <Route key={path} exact path={path} component={EmbeddedOmsPage} />
      ))}
    </Switch>
  )
}
