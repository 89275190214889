import React from 'react'
import styled from 'styled-components'
import DataActivations from 'assets/DataActivations.svg'
import { Button, standardIcons } from '@chordco/component-library'
import {
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
} from 'components/settings/integrations/requests/Common'

type EmptyDestinationsProps = {
  handleAddDestination: () => void
  canAddDestinations: boolean
}

export const EmptyDestinations: React.FC<EmptyDestinationsProps> = ({
  handleAddDestination,
  canAddDestinations,
}: EmptyDestinationsProps) => {
  return (
    <Wrapper>
      <CenteredContent gap={48}>
        <ImageContainer>
          <img src={DataActivations} alt="Data Activations" />
        </ImageContainer>

        <TextCtaContainer>
          <StyledH1>Create a destination</StyledH1>
          <StyleBody>
            To activate your audience segment, you need to create a destination for your data and
            then create a sync.
          </StyleBody>

          <Button
            onClick={handleAddDestination}
            purpose="primary"
            icon={standardIcons.Plus}
            disabled={!canAddDestinations}
            tooltip={
              !canAddDestinations ? 'Please contact an admin to add destinations' : undefined
            }
          >
            Add New Destination
          </Button>
        </TextCtaContainer>
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
`

const TextCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
