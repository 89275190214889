import { Route, Switch } from 'react-router-dom'
import { EmbeddedOmsPage } from 'components/common/EmbeddedOmsPage'

export function OmsRoutes() {
  // Define base routes that don't have identifiers
  const baseRoutes = [
    '/products',
    '/orders',
    '/stock',
    '/customers',
    '/promotions',
    '/promotion_categories',
    '/subscriptions',
    '/subscription_types',
    '/prepaid_subscriptions',
    '/imports',
  ]

  // Define routes with identifiers
  const identifierRoutes = [
    { path: '/products/:id', pattern: '/products/\\d+/edit' },
    { path: '/orders/:id', pattern: '/orders/\\d+/edit' },
    { path: '/promotions/:id', pattern: '/promotions/\\d+/edit' },
    {
      path: '/promotion_categories/:id',
      pattern: '/promotion_categories/\\d+/edit',
    },
    { path: '/subscriptions/:id', pattern: '/subscriptions/\\d+/edit' },
    {
      path: '/subscription_types/:id',
      pattern: '/subscription_types/\\d+/edit',
    },
    {
      path: '/prepaid_subscriptions/:id',
      pattern: '/prepaid_subscriptions/\\d+/edit',
    },
    { path: '/customers/:id', pattern: '/users/\\d+/edit' },
    { path: '/users/:id', pattern: '/users/\\d+/edit' },
    {
      path: '/imports/:id',
      pattern: '/imports/\\d+/import_rows/\\d+',
    },
  ]

  // Combine base routes and routes with identifiers
  const allRoutes = [
    ...baseRoutes,
    ...identifierRoutes.map(route => route.path),
  ]

  // Define the route configuration by mapping the combined routes
  const routeConfig = allRoutes.map(path => ({
    path,
    component: EmbeddedOmsPage,
  }))

  return (
    <Switch>
      {routeConfig.map(({ path, component: Component }) => (
        <Route key={path} exact path={path} component={Component} />
      ))}
    </Switch>
  )
}
