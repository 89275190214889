import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLookerData } from 'redux/state/looker'
import {
  Button,
  DateDropdown,
  DateDropdownPeriod,
  H4,
  H3,
  standardIcons,
} from '@chordco/component-library'
import { Metrics } from './Metrics'
import { SubSection } from './Home'
import useUserRole from 'redux/hooks/useUserRole'
import { useIsMobile } from 'hooks'
import { useHistory } from 'react-router'
import { useHasFailed, useIsLoading } from 'redux/utils'
import { EmptyMetrics } from './EmptyMetrics'
import { Loading } from 'components/common'
import { useTenantsData } from 'redux/state/tenants'

const { ArrowRight } = standardIcons

export const MetricsTiles = () => {
  const [period, setPeriod] = useState<DateDropdownPeriod>('week')

  const {
    state: { currentTenant },
  } = useTenantsData()

  const {
    state: { tiles },
    getTiles,
  } = useLookerData()

  const role = useUserRole()

  const validUuid = currentTenant?.chordUuid && currentTenant?.chordUuid !== ''

  const fetchTiles = useCallback(() => {
    if (validUuid) {
      getTiles && getTiles(period)
    }
  }, [period, validUuid])

  useEffect(() => {
    fetchTiles()
  }, [fetchTiles])

  const isMobile = useIsMobile()

  const history = useHistory()
  const goToAnalytics = () => history.push('/analytics/pinned-reports')

  const loading = useIsLoading('getTiles')
  const hasError = useHasFailed('getTiles')

  return (
    <>
      <H3>Store Overview</H3>

      {validUuid && (
        <DropdownContainer>
          <DateDropdown selected={period} onSelect={setPeriod} />
        </DropdownContainer>
      )}

      <SubSection isMobile={isMobile}>
        <MetricsTitle>Performance</MetricsTitle>

        {loading ? (
          <Loading hideMask={true} opacity={1} />
        ) : (
          <>
            {hasError ? (
              <EmptyMetrics message="An error occurred while loading the data. Please try again later." />
            ) : !validUuid ? (
              <EmptyMetrics message="Tenant UUID is not configured. Please contact Chord support." />
            ) : tiles.length ? (
              <Metrics metrics={tiles} />
            ) : (
              <EmptyMetrics message="No data available for the selected period." />
            )}
          </>
        )}
      </SubSection>
      <ButtonContainer>
        {role !== 'oms_admin' && (
          <Button purpose="tertiary" trailingIcon={ArrowRight} onClick={goToAnalytics}>
            View All Analytics
          </Button>
        )}
      </ButtonContainer>
    </>
  )
}

const MetricsTitle = styled(H4)`
  margin: 16px 0;
`

const DropdownContainer = styled.div`
  margin-top: 16px;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  margin-bottom: 64px;
`
