import React from 'react'
import { DropdownProps, OptionProps } from '../index'
import moment from 'moment'
import { DateRange } from 'types'
import { FormControl, Select, SelectChangeEvent, MenuItem } from '@mui/material'

export type DateDropdownPeriod = 'today' | 'week' | 'month' | 'year'

type Props = Omit<DropdownProps, 'control' | 'options'>

const dateValueMap: { [key: number]: moment.Moment } = {
  1: moment(),
  2: moment().subtract(1, 'week'),
  3: moment().subtract(30, 'day'),
  4: moment().subtract(90, 'day'),
  5: moment().subtract(1, 'year'),
  6: moment().clone().startOf('week'),
  7: moment().clone().startOf('month'),
  8: moment().clone().startOf('year'),
}

const end = moment().format('YYYY-MM-DD')

export const getDateRangeById: (id: number) => DateRange = id => {
  const start = dateValueMap[id].format('YYYY-MM-DD')

  return { start, end }
}

const options: OptionProps[] = [
  { id: 'year', label: 'Year to date' },
  { id: 'month', label: 'Month to date' },
  { id: 'week', label: 'Week to date' },
  { id: 'today', label: 'Today' },
]

export const DateDropdown: React.FC<Props> = ({ ...props }) => {
  return (
    <FormControl sx={{ minWidth: 300 }} size="small">
      <Select
        value={props.selected?.toString()}
        onChange={(event: SelectChangeEvent<string>) => {
          if (props.onSelect) props.onSelect(event.target.value)
        }}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
