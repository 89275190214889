import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { coreDestinations, DestinationType } from 'components/chord-cdp/destinations'
import { Box, Typography, Grid } from '@mui/material'
import { Cloud, Storage } from '@mui/icons-material'
import { Button } from '@chordco/component-library'
import { Header } from 'components/common'
import { ArrowLeft } from '@chordco/component-library/components/icons/standardIcons/ArrowLeft'
import AddDestinationForm from 'components/chord-cdp/destinations/AddDestinationForm'
import DestinationCard from 'components/chord-cdp/catalogs/DestinationCard'
import { useIntegrationsByEnvironment } from 'redux/hooks'

function groupDestinationTypes(
  canAccessAllDestinations: boolean
): Record<string, DestinationType[]> {
  const groups: Record<string, DestinationType[]> = {}

  const sortOrder = [
    'Datawarehouse',
    'Product Analytics',
    'Email/SMS Marketing',
    'CRM',
    'Block Storage',
    'Device Destinations',
    'Special',
  ]

  coreDestinations.forEach(d => {
    // Exclude hidden destinations unless `canAccessAllDestinations` is true
    if (d.tags && (!d.hidden || canAccessAllDestinations)) {
      const tags = typeof d.tags === 'string' ? [d.tags] : d.tags

      // Override the `comingSoon` flag and grant access to all destinations
      // if the tenant store has been configured to allow it.
      if (canAccessAllDestinations && d.comingSoon) {
        d = { ...d, comingSoon: false }
      }

      tags.forEach(tag => {
        groups[tag] = groups[tag] || []
        groups[tag].push(d)
      })
    }
  })

  // Sort destinations within each group: comingSoon = true goes to the end
  Object.keys(groups).forEach(tag => {
    groups[tag].sort((a, b) => {
      if (a.comingSoon === b.comingSoon) {
        // Fallback sort by title: if two elements have the same comingSoon value, we sort
        // alphabetically by title, preventing unpredictable reordering.
        return a.title.localeCompare(b.title)
      }

      // Sort by comingSoon: true goes to the end
      return a.comingSoon ? 1 : -1
    })
  })

  return Object.entries(groups)
    .sort(([k1], [k2]) => {
      const i1 = sortOrder.indexOf(k1)
      const i2 = sortOrder.indexOf(k2)
      if (i1 === -1 && i2 === -1) {
        return k1.localeCompare(k2)
      }
      if (i1 === -1) {
        return 1
      }
      if (i2 === -1) {
        return -1
      }
      return i1 - i2
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}

export function getDestinationIcon(destination?: DestinationType) {
  if (!destination) {
    return <Cloud />
  }
  const tags = (
    destination.tags
      ? typeof destination.tags === 'string'
        ? [destination.tags]
        : destination.tags
      : []
  ).map(t => t.toLowerCase())
  return destination.icon || (tags.includes('datawarehouse') ? <Storage /> : <Cloud />)
}

export const DestinationCatalog: React.FC = () => {
  const history = useHistory()

  const { integrations } = useIntegrationsByEnvironment()
  const jitsu = integrations.find(i => i.type === 'Hub::Jitsu')
  const canAccessAllDestinations = jitsu?.settings?.canAccessAllDestinations || false

  const groups = useMemo(() => groupDestinationTypes(canAccessAllDestinations), [])

  const [destinationToAdd, setDestinationToAdd] = useState<DestinationType | null>(null)

  const handleDestinationAdded = async () => {
    setDestinationToAdd(null)
    history.push('/chord-cdp')
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Header
          title="Destinations Catalog"
          subtitle="Send data to one of those destinations with Chord CDP"
          hideBottomBorder
        />
        <Button purpose="secondary" icon={ArrowLeft} onClick={() => history.push('/chord-cdp')}>
          Back
        </Button>
      </Box>
      <Box mt={4} display="flex" flexDirection="column" flexGrow={1} overflow="auto">
        {Object.keys(groups).length === 0 ? (
          <Typography variant="body1" color="textSecondary" textAlign="center">
            No destinations available.
          </Typography>
        ) : (
          Object.entries(groups).map(([tag, destinations]) => (
            <Box key={tag} mb={3}>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                {tag}
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                {destinations.length === 1
                  ? '1 destination available'
                  : `${destinations.length} destinations available`}
              </Typography>
              <Grid container spacing={2}>
                {destinations.map(destination => (
                  <Grid item key={destination.id}>
                    <DestinationCard
                      destination={destination}
                      handleSelectDestination={() => setDestinationToAdd(destination)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))
        )}
      </Box>

      {destinationToAdd && (
        <AddDestinationForm
          destinationType={destinationToAdd}
          onDestinationAdded={handleDestinationAdded}
          onClose={() => setDestinationToAdd(null)}
        />
      )}
    </Box>
  )
}
