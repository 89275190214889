import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_RowData,
  MRT_Row,
  MRT_TableOptions,
  MRT_PaginationState,
} from 'material-react-table'

import { getCommonProps, getMuiProps } from './TableConfig'
import { getEventProps } from './TableEvents'
import {
  getToolbarActions,
  getRowActions,
  getToolbarInternalActions,
} from './TableToolbar'
import { getTableCreateRowDialog } from './TableCreateRowDialog'
import { getTableEditRowDialog } from './TableEditRowDialog'

interface TableProps<TData extends MRT_RowData> {
  columns: MRT_ColumnDef<TData>[]
  data: TData[]
  isLoading?: boolean
  isCreating?: boolean
  isUpdating?: boolean
  isDeleting?: boolean
  onCreatingRowSave?: MRT_TableOptions<TData>['onCreatingRowSave']
  onCreatingRowCancel?: MRT_TableOptions<TData>['onCreatingRowCancel']
  onEditingRowSave?: MRT_TableOptions<TData>['onEditingRowSave']
  onEditingRowCancel?: MRT_TableOptions<TData>['onEditingRowCancel']
  onPaginationChange: MRT_TableOptions<TData>['onPaginationChange']
  openDeleteConfirmModal?: (row: MRT_Row<TData>) => void
  reloadTableData: () => void
  enableEditing: boolean
  pagination: MRT_PaginationState
  rowCount: number
  processingSheetUpload: boolean
  runIntegrityCheck: () => void
  importCSVSheet: () => void
  clearSheetData: () => void
  downloadSheet: () => void
  slug: string
}

function Table<TData extends MRT_RowData>({
  data,
  columns,
  isLoading,
  isCreating,
  isUpdating,
  isDeleting,
  onCreatingRowSave,
  onCreatingRowCancel,
  onEditingRowSave,
  onEditingRowCancel,
  openDeleteConfirmModal,
  onPaginationChange,
  reloadTableData,
  enableEditing,
  pagination,
  rowCount,
  processingSheetUpload,
  runIntegrityCheck,
  importCSVSheet,
  clearSheetData,
  downloadSheet,
  slug,
  ...rest
}: TableProps<TData>) {
  const commonProps = getCommonProps<TData>(enableEditing)

  const muiProps = getMuiProps<TData>()

  const eventProps = getEventProps<TData>({
    onCreatingRowSave,
    onCreatingRowCancel,
    onEditingRowSave,
    onEditingRowCancel,
  })

  const toolbarActions = getToolbarActions<TData>(
    runIntegrityCheck,
    importCSVSheet,
    clearSheetData,
    downloadSheet,
    processingSheetUpload,
    slug,
    enableEditing,
    data.length > 0
  )

  const toolbarInternalActions = getToolbarInternalActions<TData>(reloadTableData)

  const rowActions = getRowActions<TData>(openDeleteConfirmModal)

  const createRowDialog = getTableCreateRowDialog<TData>()
  const editRowDialog = getTableEditRowDialog<TData>(openDeleteConfirmModal)

  const table = useMaterialReactTable<TData>({
    columns,
    data: data || [],
    ...commonProps,
    ...muiProps,
    ...eventProps,
    ...toolbarActions,
    ...toolbarInternalActions,
    ...rowActions,
    ...createRowDialog,
    ...editRowDialog,
    ...rest,
    state: {
      isLoading: isLoading || false,
      isSaving: isCreating || isUpdating || isDeleting,
      showProgressBars: isDeleting,
      pagination,
    },
    initialState: {
      columnVisibility: {
        key: false,
      },
      density: 'compact',
    },
    enableSorting: false,
    manualPagination: true,
    rowCount,
    onPaginationChange,
  })

  return <MaterialReactTable table={table} />
}

export default Table
