import { useState } from 'react'
import styled from 'styled-components'
import { Button, standardIcons } from '@chordco/component-library'
import { Circle } from 'components/common'
import useUserRole from 'redux/hooks/useUserRole'
import { Plus } from '@chordco/component-library/components/icons/standardIcons/Plus'
import { CreateConfigForm } from 'components/provisioning/CreateConfigForm'
import { ChordConfigSheet } from 'types'

const { Plug } = standardIcons

interface EmptySheetsProps {
  configSheets: ChordConfigSheet[]
}

export default function EmptySheets({ configSheets }: EmptySheetsProps) {
  const role = useUserRole()

  const isCurrentUserSuperUser = role === 'superuser'

  const [showConfigUiForm, setShowConfigUiForm] = useState(false)

  const toggleConfigUiForm = () => setShowConfigUiForm(!showConfigUiForm)

  return (
    <Container>
      <BannerContainer>
        <Text>We are working hard to set up your model amplifications! Check back soon!</Text>

        {isCurrentUserSuperUser && (
          <>
            <Button icon={Plus} variant="tertiary" onClick={toggleConfigUiForm}>
              Configure
            </Button>

            {showConfigUiForm && configSheets && (
              <CreateConfigForm onClose={toggleConfigUiForm} configSheets={configSheets} />
            )}
          </>
        )}
      </BannerContainer>

      <IconContainer>
        <Circle innerCircleScale={286} outerCircleScale={414}>
          <Plug />
        </Circle>
      </IconContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BannerContainer = styled.div`
  width: 100%;
  background: ${p => p.theme.BgAccentLightAADarkInfo};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.BLACK};
`

const IconContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
`
