import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const logRocketCredentialsConfig: CredentialsConfig = {
  appId: {
    description: 'Log Rocket App ID. Go to Settings » Project Setup » General Settings to find it',
    renderComponent: TextField,
    componentProps: {
      label: 'Log Rocket App ID',
      type: 'text',
      required: true,
    },
  },
}

export const LogRocketCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={logRocketCredentialsConfig} />
)
