export default (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36">
    <path fill="#FFF382" d="M0 0h36v36H0z" />
    <path fill="#FFF382" d="M0 0h36v36H0z" />
    <path d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z" fill="#FFF382" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.343 11.033l5.162 9.703a3.428 3.428 0 01-.359 3.814 3.429 3.429 0 01-4.854.456 3.428 3.428 0 01-.843-1.051s-3.004-5.63-3.77-7.101a2.626 2.626 0 00-3.867-.953.157.157 0 01-.23-.213l2.78-4.764a3.428 3.428 0 015.897.109zm-7.84 14.57a3.77 3.77 0 10-3.13-5.86 3.77 3.77 0 00-.635 2.095 3.77 3.77 0 003.765 3.765z"
      fill="#1A1E22"
    />
  </svg>
)
