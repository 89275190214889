import { TextField } from '@mui/material'
import KeyValueList from 'components/chord-cdp/common/KeyValueList'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const snowflakeConfig: CredentialsConfig = {
  account: {
    description: 'Snowflake account name',
    renderComponent: TextField,
    componentProps: {
      label: 'Account',
      type: 'text',
      required: true,
    },
  },
  database: {
    description: 'Snowflake database name',
    renderComponent: TextField,
    componentProps: {
      label: 'Database',
      type: 'text',
      required: true,
    },
  },
  defaultSchema: {
    description: 'Snowflake schema',
    renderComponent: TextField,
    componentProps: {
      label: 'Schema',
      type: 'text',
      defaultValue: 'PUBLIC',
    },
  },
  username: {
    description: 'Snowflake username',
    renderComponent: TextField,
    componentProps: {
      label: 'Username',
      type: 'text',
      required: true,
    },
  },
  password: {
    description: 'Snowflake password',
    renderComponent: TextField,
    componentProps: {
      label: 'Password',
      type: 'password',
      required: true,
    },
  },
  warehouse: {
    description: 'Snowflake warehouse name',
    renderComponent: TextField,
    componentProps: {
      label: 'Warehouse',
      type: 'text',
      required: true,
    },
  },
  parameters: {
    description: 'Additional Snowflake connection parameters',
    renderComponent: KeyValueList,
    componentProps: {
      label: 'Parameters',
    },
  },
}

export const SnowflakeCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={snowflakeConfig} />
)
