import { TextField, Switch } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const posthogCredentialsConfig: CredentialsConfig = {
  key: {
    description: `Posthog Project API Key. Can be found in <a target='_blank' rel='noopener noreferrer' href='https://app.posthog.com/project/settings'>Project Settings</a>`,
    renderComponent: TextField,
    componentProps: {
      label: 'Project API Key',
      type: 'text',
      required: true,
    },
  },
  host: {
    description: 'Posthog host',
    renderComponent: TextField,
    componentProps: {
      label: 'Host',
      type: 'text',
      defaultValue: 'https://app.posthog.com',
    },
  },
  enableGroupAnalytics: {
    description: `Group analytics is a powerful feature in PostHog that allows you to perform analytics on entities other than single users. Group Analytics is not available on the open-source or free cloud plan. <a href='https://posthog.com/pricing' target='_blank' rel='noreferrer noopener'>Learn more.</a>`,
    renderComponent: Switch,
    componentProps: {
      label: 'Enable Group Analytics',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
  groupType: {
    description: `Group type is the abstract type of whatever our group represents (e.g. company, team, chat, post, etc.). <a href='https://posthog.com/docs/getting-started/group-analytics#groups-vs-group-types' target='_blank' rel='noreferrer noopener'>Groups vs. group types.</a>`,
    renderComponent: TextField,
    componentProps: {
      label: 'Group Type',
      type: 'text',
      defaultValue: 'company',
    },
  },
  enableAnonymousUserProfiles: {
    description: 'If enabled, anonymous users will be tracked in Posthog',
    renderComponent: Switch,
    componentProps: {
      label: 'Enable Anonymous User Profiles',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
}

export const PosthogCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={posthogCredentialsConfig} />
)
