import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Body, Button, H1 } from '@chordco/component-library'

export const AddNewAudience: React.FC = () => {
  const history = useHistory()

  return (
    <Wrapper>
      <CenteredContent>
        <StyledH1>What are Audiences?</StyledH1>
        <StyleBody>
          Create an audience using our prebuilt audience segments. Once an audience is created, you
          will see them here.
        </StyleBody>

        <Button
          name="Choose a Template Audience to Customize"
          location="Audiences"
          onClick={() => history.push('/audiences/prebuilt-audiences')}
        >
          Create an Audience
        </Button>
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: calc(100vh - 110px);
`

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: -100px;
`

const StyledH1 = styled(H1)`
  text-align: center;
`

const StyleBody = styled(Body)`
  text-align: center;
  line-height: 1.75;
  max-width: 640px;
`
