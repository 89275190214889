import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import EmptySharedReportsImg from 'assets/EmptySharedReports.svg'
import { Button, standardIcons } from '@chordco/component-library'
import {
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
} from 'components/settings/integrations/requests/Common'

export default function EmptySharedReports() {
  const history = useHistory()

  return (
    <Wrapper>
      <CenteredContent gap={48}>
        <ImageContainer>
          <img src={EmptySharedReportsImg} alt="Empty Shared Reports" />
        </ImageContainer>

        <TextCtaContainer>
          <StyledH1>Where are all of our Shared Reports?</StyledH1>
          <StyleBody>
            It is easy to create and share reports on Chord!
            <br />
            Go to &quot;My Reports&quot; to create and share a custom report for
            your Team to access.
          </StyleBody>

          <Button
            onClick={() => history.push('/analytics/my-reports')}
            purpose="tertiary"
            trailingIcon={standardIcons.ArrowDiagonal}
          >
            View My Reports
          </Button>
        </TextCtaContainer>
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: calc(100vh - 110px);
`

const TextCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
