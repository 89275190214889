import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const bigQueryCredentialsConfig: CredentialsConfig = {
  project: {
    description:
      "Google Cloud Project ID. <a target='_blank' rel='noreferrer noopener' href='https://support.google.com/googleapi/answer/7014113?hl=en'>Locate Project ID</a>",
    renderComponent: TextField,
    componentProps: {
      label: 'Project ID',
      type: 'text',
      required: true,
    },
  },
  bqDataset: {
    description:
      "BigQuery <a target='_blank' rel='noreferrer noopener' href='https://cloud.google.com/bigquery/docs/datasets-intro'>Dataset</a>",
    renderComponent: TextField,
    componentProps: {
      label: 'BigQuery Dataset',
      type: 'text',
      required: true,
    },
  },
  keyFile: {
    description:
      "Google Service Account JSON for BigQuery. <a target='_blank' rel='noreferrer noopener' href='https://cloud.google.com/iam/docs/keys-create-delete#iam-service-account-keys-create-console'>Read more about Google Authorization</a>",
    renderComponent: TextField,
    componentProps: {
      label: 'Service Account Key File',
      type: 'text',
      required: true,
    },
  },
}

export const BigQueryCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={bigQueryCredentialsConfig} />
)
