import { useEffect, useState } from 'react'
import DataActivations from 'assets/DataActivations.svg'
import { Button, H4, Spinner, standardIcons } from '@chordco/component-library'
import AccessRequestConfirmed from './AccessRequestConfirmed'
import {
  Header,
  TitleContainer,
  ButtonsContainer,
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
  LoadingContainer,
} from './Common'
import { useNotion } from 'hooks/useNotion'
import { useAuthData } from 'redux/state/auth'
import { useTenantsData } from 'redux/state/tenants'

type Props = {
  showHeader?: boolean
}

export default function EmptyActivations({ showHeader = true }: Props) {
  const {
    state: { currentTenant },
  } = useTenantsData()

  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')
  if (!currentTenant) throw new Error('No current tenant')

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const { createFeatureAccessRequest, fetchFeatureAccessRequests } = useNotion()

  const handleClick = async () => {
    try {
      await createFeatureAccessRequest({
        tenantId: currentTenant.id,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email ?? 'n/a',
        feature: 'data_activations',
      })

      setShowConfirmation(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating feature access request', error)
    }
  }

  useEffect(() => {
    const fetchAccessRequest = async () => {
      try {
        setIsFetching(true)

        const response = await fetchFeatureAccessRequests(currentTenant.id, 'data_activations')

        if (response.results.length > 0) {
          setShowConfirmation(true)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching feature access requests', error)
      } finally {
        setIsFetching(false)
      }
    }

    fetchAccessRequest()
  }, [fetchFeatureAccessRequests, currentTenant.id])

  return (
    <>
      {showHeader && (
        <Header>
          <TitleContainer>
            <H4>My Destinations</H4>
            <Button type="button" purpose="ghost" icon={standardIcons.Refresh} disabled={true}>
              Refresh
            </Button>
          </TitleContainer>

          <ButtonsContainer>
            <Button type="button" icon={standardIcons.Sync} variant="outlined" disabled={true}>
              Manage Syncs
            </Button>

            <Button type="button" icon={standardIcons.Plus} disabled={true}>
              Add Destination
            </Button>
          </ButtonsContainer>
        </Header>
      )}

      <CenteredContent>
        <ImageContainer>
          <img src={DataActivations} alt="Activations" />
        </ImageContainer>

        <StyledH1>Effortlessly Sync and Activate Your Data</StyledH1>
        <StyleBody>
          Be among the first to experience seamless data integration and activation. Boost your
          marketing efficiency, gain deeper insights, and drive higher engagement.
          <br />
          Request access now!
        </StyleBody>

        {isFetching && (
          <LoadingContainer>
            <Spinner scale={30} />
          </LoadingContainer>
        )}

        {!isFetching && (
          <>
            {showConfirmation ? (
              <AccessRequestConfirmed />
            ) : (
              <Button onClick={handleClick}>Request Access</Button>
            )}
          </>
        )}
      </CenteredContent>
    </>
  )
}
