import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, TableRow } from 'components/common/table'
import { CensusDestination, CensusSync, CensusSyncRun } from 'api/census/interfaces'
import { SyncChip } from './SyncChip'
import { Button, standardIcons, Spinner } from '@chordco/component-library'
import { useCensus } from '../CensusContext'
import { formatRelativeTime } from 'utils/dateTime'
import { ConfirmModal } from '../dialogs/ConfirmModal'
import { formatSchedule } from '../utils/time'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

const { Edit, PlayOutline, Stop, Trash } = standardIcons

type Props = {
  sync: CensusSync
  onRunSync: (sync: CensusSync) => void
  onCancelSync: (sync: CensusSync) => void
  onDeleteSync: (sync: CensusSync) => void
  onEditSync: (sync: CensusSync) => void
  onUpdateSyncSchedule: (sync: CensusSync) => void
  canUpdateSync: boolean
}

export const SyncRow: React.FC<Props> = ({
  sync,
  onRunSync,
  onCancelSync,
  onDeleteSync,
  onEditSync,
  onUpdateSyncSchedule,
  canUpdateSync,
}) => {
  const getSyncSchedule = () => {
    const { scheduleDay, scheduleFrequency, scheduleHour, scheduleMinute, cronExpression } = sync

    if (scheduleFrequency === 'never') return 'Not Scheduled'

    const formatted = formatSchedule(
      scheduleDay,
      scheduleFrequency,
      scheduleHour,
      scheduleMinute,
      cronExpression
    )

    return formatted
  }

  const [isLoading, setIsLoading] = useState(true)

  const [lastRun, setLastRun] = useState<CensusSyncRun | undefined>(undefined)
  const [destination, setDestination] = useState<CensusDestination | undefined>(undefined)

  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false)
  const [showCancelAlertModal, setShowCancelAlertModal] = useState(false)

  const { censusClient } = useCensus()
  const { trackEvent } = useMixpanel()

  const fetchData = useCallback(async () => {
    setIsLoading(true)

    try {
      const connectionId = sync.destinationAttributes['connectionId']

      const [syncRunResponse, destinationResponse] = await Promise.all([
        censusClient?.getSyncRuns(sync.id),
        censusClient?.getDestination(connectionId),
      ])

      if (syncRunResponse) {
        setLastRun(syncRunResponse.data.length > 0 ? syncRunResponse.data[0] : undefined)
      }

      if (destinationResponse) {
        setDestination(destinationResponse.data)
      }
    } finally {
      setIsLoading(false)
    }
  }, [censusClient, sync])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleDeleteConfirmation = () => {
    setShowDeleteAlertModal(false)
    setShowCancelAlertModal(false)
    onDeleteSync(sync)
  }

  const handleCancelConfirmation = () => {
    setShowDeleteAlertModal(false)
    setShowCancelAlertModal(false)
    onCancelSync(sync)
  }

  const formatSourceName = () => {
    const attributes = sync.sourceAttributes['object']
    const dataSetName = attributes['name']
    const tableName = `${attributes['tableSchema']}.${attributes['tableName']}`
    return dataSetName ?? tableName ?? 'N/A'
  }

  const trackAction = iconName => {
    trackEvent(MixpanelEventType.ActionItemClicked, {
      'Icon Name': iconName,
      'Page Name': 'Data Activations',
      'Slidesheet Name': 'My Syncs',
      'Table Name': 'My Syncs',
    })
  }

  return (
    <Container>
      <Col flex={1}>
        {isLoading ? (
          <Spinner scale={30} />
        ) : (
          <SyncChip status={lastRun?.status ?? 'Not run yet'} />
        )}
      </Col>
      <StyledCol flex={1}>
        <Title>{sync.label ?? '-'}</Title>
      </StyledCol>
      <StyledCol flex={1}>
        <Title>{formatSourceName()}</Title>
      </StyledCol>
      <StyledCol flex={1}>
        {isLoading ? <Spinner scale={30} /> : <Title>{destination?.name ?? '-'}</Title>}
      </StyledCol>
      <Col flex={1}>
        {isLoading ? (
          <Spinner scale={30} />
        ) : (
          <LastRun>
            {lastRun?.completedAt
              ? formatRelativeTime(lastRun?.completedAt)
              : lastRun?.updatedAt
              ? formatRelativeTime(lastRun?.updatedAt)
              : 'Not run yet'}
          </LastRun>
        )}
      </Col>
      <Col flex={1}>
        <RunSchedule>{getSyncSchedule()}</RunSchedule>
      </Col>
      <Col flex={1}>
        <ActionButtonContainer>
          <ActionButton
            purpose="primary"
            variant="ghost"
            icon={PlayOutline}
            onClick={() => {
              trackAction('Run')
              onRunSync(sync)
            }}
            tooltip={!canUpdateSync ? 'Please contact an admin to run syncs' : 'Run sync'}
            disabled={!canUpdateSync}
          />
          <ActionButton
            purpose="secondary"
            variant="ghost"
            icon={Stop}
            onClick={() => {
              trackAction('Stop')
              setShowCancelAlertModal(true)
            }}
            tooltip={!canUpdateSync ? 'Please contact an admin to stop syncs' : 'Stop sync'}
            disabled={!canUpdateSync}
          />
          <ActionButton
            purpose="destructive"
            variant="ghost"
            icon={Trash}
            onClick={() => {
              trackAction('Delete')
              setShowDeleteAlertModal(true)
            }}
            tooltip={!canUpdateSync ? 'Please contact an admin to delete syncs' : 'Delete sync'}
            disabled={!canUpdateSync}
          />
          <ActionButton
            purpose="primary"
            variant="ghost"
            icon={Edit}
            onClick={() => {
              trackAction('Edit')
              onEditSync(sync)
            }}
            tooltip={!canUpdateSync ? 'Please contact an admin to edit syncs' : 'Edit sync'}
            disabled={!canUpdateSync}
          />
          <ActionButton
            purpose="primary"
            variant="ghost"
            icon={standardIcons.Time}
            onClick={() => {
              trackAction('Update Schedule')
              onUpdateSyncSchedule(sync)
            }}
            tooltip={!canUpdateSync ? 'Please contact an admin to update syncs' : 'Update sync'}
            disabled={!canUpdateSync}
          />
        </ActionButtonContainer>
      </Col>

      {showDeleteAlertModal && canUpdateSync && (
        <ConfirmModal
          title="Are you sure you want to delete this sync?"
          subtitle="This action cannot be undone"
          confirmText="Delete sync"
          closeText="No, keep sync"
          onConfirm={handleDeleteConfirmation}
          onClose={() => setShowDeleteAlertModal(false)}
        />
      )}

      {showCancelAlertModal && canUpdateSync && (
        <ConfirmModal
          title="Are you sure you want to stop this sync?"
          confirmText="Stop sync"
          closeText="No, keep running"
          onConfirm={handleCancelConfirmation}
          onClose={() => setShowCancelAlertModal(false)}
        />
      )}
    </Container>
  )
}

const Container = styled(TableRow)`
  height: 56px;
  border-bottom: 1px solid ${p => p.theme.ComponentCardBorderElevation0};

  :first-child {
    border-top-right-radius: ${p => p.theme.CardMediumRadius};
    border-top-left-radius: ${p => p.theme.CardMediumRadius};
  }

  :last-child {
    border-bottom-right-radius: ${p => p.theme.CardMediumRadius};
    border-bottom-left-radius: ${p => p.theme.CardMediumRadius};
    border-bottom: none;
  }
`

const Title = styled.span`
  font-size: 12px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
`

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`

const ActionButton = styled(Button)`
  height: 26px;
  width: 26px;
  margin: auto;
  border: none;
`

const LastRun = styled.span`
  font-size: 12px;
`

const RunSchedule = styled.span`
  font-size: 12px;
`
