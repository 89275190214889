import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const googleAnalyticsCredentialsConfig: CredentialsConfig = {
  apiSecret: {
    description:
      `An <code>API SECRET</code> generated in the Google Analytics UI. To create a new secret, navigate to:<br/>` +
      `<b>Admin > Data Streams > choose your stream > Measurement Protocol API Secrets > Create</b>`,
    renderComponent: TextField,
    componentProps: {
      label: 'API Secret',
      type: 'password',
      required: true,
    },
  },
  measurementId: {
    description:
      `The measurement ID associated with a stream.<br/><b>For Web:</b> found in the Google Analytics UI under: ` +
      `<b>Admin > Data Streams > choose your stream > Measurement ID</b><br/><b>For Apps</b>: the Firebase App ID, found in the Firebase console under: ` +
      `<b>Project Settings > General > Your Apps > App ID</b>`,
    renderComponent: TextField,
    componentProps: {
      label: 'Measurement ID',
      type: 'text',
      required: true,
    },
  },
  events: {
    description: `List of events to send, delimited by comma. Following <code>page</code>, <code>screen</code>, or any arbitrary event (name of <code>track</code> event).
Special values: <b>empty string</b> - send only <code>track</code> events, <b>*</b> - send all events useful if you want to filter events with Functions
`,
    renderComponent: TextField,
    componentProps: {
      label: 'Events',
      type: 'text',
    },
  },
}

export const GoogleAnalyticsCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={googleAnalyticsCredentialsConfig} />
)
