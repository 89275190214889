import React from 'react'
import styled from 'styled-components'
import { Button, Label, Modal } from '@chordco/component-library'
import { FormInput, FormTextArea } from 'components/common'
import { useForm } from 'hooks'
import { valTypes } from 'validationSchemas'
import { PublishAudienceData, usePublishAudience } from 'components/looker/usePublishAudience'
import { AvailableAudience, AvailableUserAudience } from 'types'

interface ActivateAudienceModalProps {
  audience: AvailableAudience
  onClose: () => void
  onPublish: (audience: AvailableUserAudience) => void
  onNameAlreadyTaken: (data: PublishAudienceData) => void
}

export const ActivateAudienceModal: React.FC<ActivateAudienceModalProps> = ({
  audience,
  onClose,
  onPublish,
  onNameAlreadyTaken,
}) => {
  const { publish, isLoading } = usePublishAudience({ onPublish, onNameAlreadyTaken })

  const submit = async () => {
    const audienceData = {
      audienceName: fields.audienceName.value.toString(),
      audienceDescription: fields.audienceDescription.value.toString(),
      audienceSlug: fields.audienceSlug.value.toString(),
    }

    await publish(audienceData, false)
  }

  const schema = {
    audienceName: {
      label: 'Audience Name',
      type: valTypes.string,
      initialValue: audience.name,
      required: true,
    },
    audienceDescription: {
      label: 'Description',
      type: valTypes.string,
      initialValue: audience.description,
      required: false,
    },
    audienceSlug: {
      label: 'Slug',
      type: valTypes.string,
      initialValue: audience.url.split('/').pop(),
      required: true,
    },
  }

  const { fields, onSubmit } = useForm(schema, submit)

  return (
    <Modal
      onClose={onClose}
      title="Create New Audience"
      width="480px"
      name="Create Audience"
      location="Audiences"
      type="Confirmation Screen"
    >
      <form onSubmit={onSubmit} noValidate>
        <Section>
          <StyledLabel>
            Creating a new audience from prebuilt audiences will also make it accessible to your
            entire team. Once a new audience is created, it will appear in the Audiences tab.
          </StyledLabel>
          <FormInput field={fields.audienceName} customWidth="100%" required />
          <FormTextArea field={fields.audienceDescription} customWidth="100%" />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" purpose="primary" isLoading={isLoading} disabled={isLoading}>
            Create Audience
          </Button>
        </BottomSection>
      </form>
    </Modal>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
