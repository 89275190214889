import { TextField, Switch } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const intercomCredentialsConfig: CredentialsConfig = {
  accessToken: {
    description:
      "Intercom Access Token. You should first create an app in Intercom Developer Hub, and then generate an access token in the app settings. See <a href='https://developers.intercom.com/docs/build-an-integration/getting-started/' target='_blank' rel='noreferrer noopener'>a detailed guide</a>",
    renderComponent: TextField,
    componentProps: {
      label: 'Access Token',
      type: 'text',
      required: true,
    },
  },
  updateLastSeenOnEveryEvent: {
    description:
      'By default, the last seen property will be updated only on .identify() calls. If enabled, the property will be updated on every event. However, enabling this option may lead to higher API usage.',
    renderComponent: Switch,
    componentProps: {
      label: 'Update Last Seen On Every Event',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
}

export const IntercomCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={intercomCredentialsConfig} />
)
