import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import { AvailableUserReport, LookerReportType, PublishDashboardResponse } from 'types'
import { PayloadAction } from '@reduxjs/toolkit'

interface PublishDashboardData {
  name: string
  description: string
  slug: string
  type: LookerReportType
}

export interface PublishDashboardCallbacks {
  onPublish: (dashboard: AvailableUserReport) => void
  onNameAlreadyTaken?: (data: PublishDashboardData) => void
}

export const usePublishDashboard = ({
  onPublish,
  onNameAlreadyTaken,
}: PublishDashboardCallbacks) => {
  const {
    state: { availableSharedReports },
    publishDashboard,
  } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)

  const isNameTaken = (name: string) =>
    availableSharedReports?.dashboards.some(dashboard => dashboard.label === name)

  const publish = async (data: PublishDashboardData, replaceExisting = false) => {
    if (!publishDashboard) return

    setIsLoading(true)

    try {
      if (!replaceExisting && isNameTaken(data.name)) {
        onNameAlreadyTaken?.(data)
        return
      }

      const response = (await publishDashboard(
        data.name,
        data.description,
        data.slug,
        replaceExisting
      )) as PayloadAction<{ data: PublishDashboardResponse }>

      const dashboard = response.payload.data.dashboard

      if (dashboard) onPublish(dashboard)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Publish dashboard error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return { publish, isLoading }
}
