import React from 'react'
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { AvailableUserAudience } from 'types'

type AudienceFolderDropdownProps = {
  title: string
  audiences: AvailableUserAudience[]
  onChange: (event: SelectChangeEvent<string>) => void
  defaultValue?: string
}

export const AudienceFolderDropdown: React.FC<AudienceFolderDropdownProps> = ({
  title,
  audiences,
  onChange,
  defaultValue,
}) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 300 }}>
      <InputLabel htmlFor="shared-audiences-dropdown">{title}</InputLabel>
      <Select
        value={defaultValue}
        id="shared-audiences-dropdown"
        label={title}
        onChange={(event: SelectChangeEvent<string>) => onChange(event)}
      >
        <ListSubheader>Audiences</ListSubheader>
        {audiences.length > 0 &&
          audiences.map((audience, idx) => (
            <MenuItem key={`${audience.slug}-${idx}`} value={audience.id}>
              {audience.label}
            </MenuItem>
          ))}

        {audiences.length === 0 && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
