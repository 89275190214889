import React from 'react'
import {
  Route,
  Switch,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom'
import { Audiences } from 'components/analytics/Audiences'
import Audience from 'components/analytics/audiences/Audience'

const AudiencesRoutes: React.FC<RouteComponentProps> = ({ match }) => {
  // Prebuilt Audiences are rendered fullpage, so we don't want to show the main
  // Audiences component with the My Audiences/Prebuilt Audiences Tab controller
  const isAudienceRoute = useRouteMatch(`${match.path}/prebuilt-audiences/:slug`)

  return (
    <div>
      {!isAudienceRoute && <Audiences />}
      <Switch>
        <Route path={`${match.path}/prebuilt-audiences/:slug`} component={Audience} />
      </Switch>
    </div>
  )
}

export default AudiencesRoutes
