import { useEffect, useState } from 'react'
import DataSources from 'assets/DataSources.svg'
import { Button, H4, Spinner, standardIcons } from '@chordco/component-library'
import { Tenant, User } from 'types'
import AccessRequestConfirmed from './AccessRequestConfirmed'
import {
  Header,
  TitleContainer,
  ButtonsContainer,
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
  LoadingContainer,
} from './Common'
import { useNotion } from 'hooks/useNotion'

type Props = {
  tenant: Tenant
  user: User
}

export default function EmptySources({ tenant, user }: Props) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const { createFeatureAccessRequest, fetchFeatureAccessRequests } = useNotion()

  const handleClick = async () => {
    try {
      await createFeatureAccessRequest({
        tenantId: tenant.id,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email ?? 'n/a',
        feature: 'data_sources',
      })

      setShowConfirmation(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating feature access request', error)
    }
  }

  useEffect(() => {
    const fetchAccessRequest = async () => {
      try {
        setIsFetching(true)

        const response = await fetchFeatureAccessRequests(
          tenant.id,
          'data_sources'
        )

        if (response.results.length > 0) {
          setShowConfirmation(true)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching feature access requests', error)
      } finally {
        setIsFetching(false)
      }
    }

    fetchAccessRequest()
  }, [fetchFeatureAccessRequests, tenant.id])

  return (
    <>
      <Header>
        <TitleContainer>
          <H4>My Data Sources</H4>
          <Button
            type="button"
            purpose="ghost"
            icon={standardIcons.Refresh}
            disabled={true}
          >
            Refresh
          </Button>
        </TitleContainer>

        <ButtonsContainer>
          <Button type="button" icon={standardIcons.Plus} disabled={true}>
            Add Source
          </Button>
        </ButtonsContainer>
      </Header>

      <CenteredContent>
        <ImageContainer>
          <img src={DataSources} alt="Data Sources" />
        </ImageContainer>

        <StyledH1>Unify Your Data, Unleash Your Potential</StyledH1>
        <StyleBody>
          Seamlessly integrate diverse data sources into a single, cohesive
          platform, transforming fragmented information into powerful insights
          that drive strategic growth and innovation.
        </StyleBody>

        {isFetching && (
          <LoadingContainer>
            <Spinner scale={30} />
          </LoadingContainer>
        )}

        {!isFetching && (
          <>
            {showConfirmation ? (
              <AccessRequestConfirmed />
            ) : (
              <Button onClick={handleClick}>Request Access</Button>
            )}
          </>
        )}
      </CenteredContent>
    </>
  )
}
