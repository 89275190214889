export default (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 67.8 45.4"
    width="100%"
    height="100%"
  >
    <g>
      <path d="M67.8,45.4H0V0h67.8L53.6,22.7L67.8,45.4L67.8,45.4z"></path>
    </g>
  </svg>
)
