export default (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 102 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.7 18.5C38.1 11.5 44 5.4 50.9 0.800003C57.7 5.3 63.5 11.3 67.9 18.2C79.4 34.2 85.2 53.5 84.4 73.2C88 76.1 91.7 78.9 95.2 81.8C100.2 86.3 102.6 93.1 101.4 99.7C99.7 107.9 98.1 116.1 96.3 124.3C95.1 127.9 91.2 129.8 87.7 128.6C87.1 128.4 86.5 128.1 86 127.7C80.2 123.1 74.6 118.3 68.8 113.6C58.9 123 43.5 123.1 33.5 113.8C29.4 116.8 25.5 120.5 21.5 123.8C19.7 125.5 17.8 127.1 15.6 128.3C12.1 129.7 8.09998 127.9 6.79998 124.4C6.69998 124.2 6.69998 124 6.59998 123.9C4.79998 115.9 2.79998 107.9 0.99998 99.9C-0.40002 92.9 2.09998 85.8 7.49998 81.2C10.6 78.7 13.8 76.2 17 73.7C17.9 73.2 17.4 72.2 17.5 71.5C17.1 52.6 22.8 34 33.7 18.5ZM39.4 43.3C35.2 48.7 35.7 56.4 40.6 61.2C46.2 66.6 55.1 66.8 60.9 61.6C65 57.9 66.4 52 64.5 46.8C62.5 41.8 57.9 38.3 52.5 37.9C47.4 37.3 42.5 39.4 39.4 43.3Z"
      fill="#764ABC"
    />
    <path
      d="M31.7 130.6C31.8 128.7 33.5 127.3 35.3 127.5C35.8 127.5 36.3 127.7 36.7 127.9C45.6 132.2 56 132.2 65 127.9C66.7 127 68.7 127.7 69.6 129.3C69.8 129.6 69.9 130 70 130.4C70 135.4 70 140.4 70 145.4C69.8 147.2 68.1 148.6 66.3 148.4C65.7 148.3 65.2 148.1 64.7 147.8C63.2 146.5 61.9 145.1 60.4 143.8C58.1 148.1 56 152.4 53.7 156.7C52.7 158.3 50.6 158.7 49 157.7C48.6 157.5 48.3 157.1 48.1 156.8C45.8 152.5 43.7 148.2 41.3 143.9C39.9 145.3 38.5 146.7 37 147.9C35.5 149 33.3 148.6 32.3 147C32 146.5 31.8 146 31.7 145.4C31.7 140.5 31.7 135.5 31.7 130.6Z"
      fill="#764ABC"
    />
    <path
      d="M50.8 58.5C54.6 58.6 57.7 55.6 57.8 51.8C57.7 48 54.6 45 50.8 45C47 44.9 43.9 47.9 43.8 51.7C43.8 55.5 47 58.6 50.8 58.5Z"
      fill="#764ABC"
    />
  </svg>
)
