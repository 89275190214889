export default (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
  >
    <path d="M 3 3 L 3 5 L 3 6 L 3 7 L 3 21 L 21 21 L 21 5 L 21 3 L 3 3 z M 5 7 L 19 7 L 19 19 L 5 19 L 5 7 z M 10 9.7929688 L 6.7929688 13 L 10 16.207031 L 11 15.207031 L 8.7929688 13 L 11 10.792969 L 10 9.7929688 z M 14 9.7929688 L 13 10.792969 L 15.207031 13 L 13 15.207031 L 14 16.207031 L 17.207031 13 L 14 9.7929688 z" />
  </svg>
)
