import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import { AvailableUserReport, LookerReportType, PublishLookResponse } from 'types'
import { PayloadAction } from '@reduxjs/toolkit'

interface PublishLookData {
  name: string
  description: string
  slug: string
  type: LookerReportType
}

export interface PublishLookCallbacks {
  onPublish: (look: AvailableUserReport) => void
  onNameAlreadyTaken?: (data: PublishLookData) => void
}

export const usePublishLook = ({ onPublish, onNameAlreadyTaken }: PublishLookCallbacks) => {
  const {
    state: { availableSharedReports },
    publishLook,
  } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)

  const isNameTaken = (name: string) =>
    availableSharedReports?.looks.some(look => look.label === name)

  const publish = async (data: PublishLookData, replaceExisting = false) => {
    if (!publishLook) return

    setIsLoading(true)

    try {
      if (!replaceExisting && isNameTaken(data.name)) {
        onNameAlreadyTaken?.(data)
        return
      }

      const response = (await publishLook(
        data.name,
        data.description,
        data.slug,
        replaceExisting
      )) as PayloadAction<{
        data: PublishLookResponse
      }>

      const look = response.payload.data.look

      if (look) onPublish(look)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Publish look error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return { publish, isLoading }
}
