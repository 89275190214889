import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const klaviyoCredentialsConfig: CredentialsConfig = {
  apiKey: {
    description:
      "You can find this by going to Klaviyo's UI and clicking Account > Settings > API Keys > Create API Key",
    renderComponent: TextField,
    componentProps: {
      label: 'Klaviyo API Key',
      type: 'text',
      required: true,
    },
  },
}

export const KlaviyoCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={klaviyoCredentialsConfig} />
)
