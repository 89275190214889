import { H6 } from '@chordco/component-library'
import {
  ConfirmationContainer,
  ConfirmationContent,
  StyledCaption,
} from 'components/settings/integrations/requests/Common'
import styled from 'styled-components'

export function SeatLimitReachedWarning() {
  return (
    <>
      <RequestSeatContainer>
        <RequestSeatContent>
          <H6>Your Team has reached the seats limit!</H6>
          <StyledCaption>
            Request more seats by clicking the button below
          </StyledCaption>
        </RequestSeatContent>
      </RequestSeatContainer>
    </>
  )
}

export function SeatLimitIncreaseRequest() {
  return (
    <>
      <ConfirmationContainer>
        <ConfirmationContent>
          <H6>Seats limit request is sent!</H6>
          <StyledCaption>
            Our team is on it. We&apos;ll reach out to you shortly.
          </StyledCaption>
        </ConfirmationContent>
      </ConfirmationContainer>
    </>
  )
}

const RequestSeatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 56px;
  background-color: ${p => p.theme.BgAccentLightAADarkWarning};
  border-radius: 8px;
`

const RequestSeatContent = styled.div`
  padding: 16px;
`
