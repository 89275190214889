import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Section } from 'components/common'
import { useIsMobile } from 'hooks'
import { AudienceCard } from './AudienceCard'
import { AvailableAudience } from 'types'
import EmptyAudiences from 'components/settings/integrations/requests/EmptyAudiences'
import ScrollableContainer from 'components/common/ScrollableContainer'

type PrebuiltAudiencesProps = {
  audiences: AvailableAudience[]
}

const PrebuiltAudiences: React.FC<PrebuiltAudiencesProps> = ({
  audiences,
}: PrebuiltAudiencesProps) => {
  const history = useHistory()
  const isMobile = useIsMobile()

  const handleSelectAudience = (audience: AvailableAudience) => {
    history.push({
      pathname: `/audiences/prebuilt-audiences/${audience.slug}`,
    })
  }

  return (
    <ScrollableContainer height="calc(100vh - 120px)" padding="16px 0px 32px 0px">
      <Section isMobile={isMobile} hasBorder={false}>
        <>
          {audiences.length === 0 && <EmptyAudiences />}

          {audiences.length > 0 && (
            <AudiencesContainer>
              {audiences.map(audience => (
                <AudienceCard
                  key={audience.slug}
                  audience={audience}
                  onSelectAudience={handleSelectAudience}
                />
              ))}
            </AudiencesContainer>
          )}
        </>
      </Section>
    </ScrollableContainer>
  )
}

const AudiencesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 16px;
`

export default PrebuiltAudiences
