import { H6 } from '@chordco/component-library'
import {
  ConfirmationContainer,
  ConfirmationContent,
  StyledCaption,
} from './Common'

export default function AccessRequestConfirmed() {
  return (
    <>
      <ConfirmationContainer>
        <ConfirmationContent>
          <H6>Access Request Received!</H6>
          <StyledCaption>
            Our team is on it. We&apos;ll reach out to you shortly.
          </StyledCaption>
        </ConfirmationContent>
      </ConfirmationContainer>
    </>
  )
}
