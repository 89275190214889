import styled from 'styled-components'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import { Body, Button } from '@chordco/component-library'
import { DuplicateError, SheetApiError } from './Sheet'

interface ApiErrorDialogProps {
  error: SheetApiError
  onClose: () => void
}

export default function ApiErrorDialog({
  error,
  onClose,
}: ApiErrorDialogProps) {
  const isDuplicateError = (error: SheetApiError): error is DuplicateError => {
    return (
      typeof error !== 'string' && 'reason' in error && 'duplicates' in error
    )
  }

  const renderErrorContent = () => {
    if (typeof error === 'string') {
      return <DialogContentText>{error}</DialogContentText>
    } else if (isDuplicateError(error)) {
      const uniqueColumns = error.duplicates.headers
        .filter(header => header.unique)
        .map(header => header.name)
        .join(', ')

      return (
        <>
          <Body>
            The following row(s) contain duplicate data based on the unique
            combination of the following column(s):{' '}
            <strong>{uniqueColumns}</strong>
          </Body>
          <StyledTable>
            <thead>
              <tr>
                <th>Row Index</th>
                {error.duplicates.headers.map((header, index) => (
                  <th key={index}>{header.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {error.duplicates.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row.index}</td>
                  {row.values.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )
    }
  }

  return (
    <Dialog open={Boolean(error)} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        {typeof error === 'string' ? error : error.reason}
      </DialogTitle>
      <DialogContent>{renderErrorContent()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} purpose="primary" type="button">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  overflow-x: auto;

  th,
  td {
    border: 1px solid ${p => p.theme.ComponentFieldBorderDefault};
    background-color: ${p => p.theme.ComponentTableBgRowDefault};
    padding: 8px;
    text-align: left;
    font-size: 12px;
  }

  th {
    background-color: ${p => p.theme.ComponentTableBgHeader};
  }

  td {
    word-break: break-word;
  }
`
