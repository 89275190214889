import React from 'react'
import styled from 'styled-components'
import { useTenantsData } from 'redux/state/tenants'
import { useEnabledCensus } from 'hooks/useEnabledCensus'
import { useIsLoading } from 'redux/utils'
import { useEnvironmentsData } from 'redux/state/environments'
import EmptyActivations from 'components/settings/integrations/requests/EmptyActivations'
import { MyDestinations } from 'components/settings/integrations/forms/census/components'
import { CensusProvider } from 'components/settings/integrations/forms/census/CensusContext'
import { Loading } from 'components/common'

export const SyncedAudiences: React.FC = () => {
  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  if (!currentTenant) throw new Error('No current tenant')
  if (!currentStore) throw new Error('currentStore is undefined')
  if (!selectedEnvironment) throw new Error('No selected environment')

  const isCensusActive = useEnabledCensus()

  const loading = useIsLoading('getStoreSetup')

  return (
    <Container>
      {loading && <Loading />}

      {!loading && (
        <>
          {isCensusActive ? (
            <CensusProvider
              clientOptions={{
                tenantId: currentTenant.id,
                storeId: currentStore.id,
                environment: selectedEnvironment,
              }}
            >
              <MyDestinations />
            </CensusProvider>
          ) : (
            <EmptyActivations showHeader={false} />
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding: 16px 0;
`
