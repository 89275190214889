import styled from 'styled-components'
import { standardIcons } from '@chordco/component-library'
import { Circle } from '../../common'

const { Plug } = standardIcons

export default function EmptyIntegrations() {
  return (
    <>
      <BannerContainer>
        <Text>
          We are working hard to set up your third party integrations! Check
          back soon!
        </Text>
      </BannerContainer>

      <IconContainer>
        <Circle innerCircleScale={286} outerCircleScale={414}>
          <Plug />
        </Circle>
      </IconContainer>
    </>
  )
}

const BannerContainer = styled.div`
  width: 100%;
  background: ${p => p.theme.BgAccentLightAADarkInfo};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
`

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.BLACK};
`

const IconContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
`
