import styled from 'styled-components'
import DataActivations from 'assets/DataActivations.svg'
import {
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
} from 'components/settings/integrations/requests/Common'

export default function EmptySyncs() {
  return (
    <Wrapper>
      <CenteredContent gap={0}>
        <ImageContainer>
          <img src={DataActivations} alt="Data Activations" />
        </ImageContainer>

        <TextCtaContainer>
          <StyledH1>Create a sync</StyledH1>
          <StyleBody>
            You haven’t synced any audiences yet! Click on &quot;Add New Sync&quot; to sync an
            audience to a destination.
          </StyleBody>
        </TextCtaContainer>
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: calc(100vh - 250px);
`

const TextCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
