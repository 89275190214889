import { Header, Section } from 'components/common'
import { SimpleBar, Spinner } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { useTenantsData } from 'redux/state/tenants'
import { useIsLoading } from 'redux/utils'
import { useEnvironmentsData } from 'redux/state/environments'
import EmptyActivations from 'components/settings/integrations/requests/EmptyActivations'
import { MyDestinations } from 'components/settings/integrations/forms/census/components'
import { CensusProvider } from 'components/settings/integrations/forms/census/CensusContext'
import dataActivationsHelp from 'help/data_activations.md'
import { useEnabledCensus } from 'hooks/useEnabledCensus'

export const DataActivations = () => {
  const isMobile = useIsMobile()

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  if (!currentTenant) throw new Error('No current tenant')
  if (!currentStore) throw new Error('currentStore is undefined')
  if (!selectedEnvironment) throw new Error('No selected environment')

  const isCensusActive = useEnabledCensus()

  const loading = useIsLoading('getStoreSetup')

  return (
    <>
      <Header
        title="Data Activations"
        subtitle="Where Chord data gets activated in connected destinations"
        helpFile={dataActivationsHelp}
      />
      <SimpleBar>
        <Section isMobile={isMobile} hasBorder={false}>
          {loading && <Spinner scale={30} />}

          {!loading && !isCensusActive && <EmptyActivations />}

          {!loading && isCensusActive && (
            <CensusProvider
              clientOptions={{
                tenantId: currentTenant.id,
                storeId: currentStore.id,
                environment: selectedEnvironment,
              }}
            >
              <MyDestinations />
            </CensusProvider>
          )}
        </Section>
      </SimpleBar>
    </>
  )
}
