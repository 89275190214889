import { TextField, Select, Switch } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const brazeCredentialsConfig: CredentialsConfig = {
  apiKey: {
    description: `Created under Developer Console in the Braze Dashboard.`,
    renderComponent: TextField,
    componentProps: {
      label: 'API Key',
      type: 'text',
      required: true,
    },
  },
  endpoint: {
    description: `Your Braze REST endpoint. <a target='_blank' rel='noopener noreferrer' href='https://www.braze.com/docs/api/basics/#endpoints'>More details</a>`,
    renderComponent: Select,
    componentProps: {
      label: 'Endpoint',
      defaultValue: 'US-01 : dashboard-01.braze.com',
      fullWidth: true,
      required: true,
      options: [
        { id: 'US-01 : dashboard-01.braze.com', value: 'US-01 : dashboard-01.braze.com' },
        { id: 'US-02 : dashboard-02.braze.com', value: 'US-02 : dashboard-02.braze.com' },
        { id: 'US-03 : dashboard-03.braze.com', value: 'US-03 : dashboard-03.braze.com' },
        { id: 'US-04 : dashboard-04.braze.com', value: 'US-04 : dashboard-04.braze.com' },
        { id: 'US-05 : dashboard-05.braze.com', value: 'US-05 : dashboard-05.braze.com' },
        { id: 'US-06 : dashboard-06.braze.com', value: 'US-06 : dashboard-06.braze.com' },
        { id: 'US-07 : dashboard-07.braze.com', value: 'US-07 : dashboard-07.braze.com' },
        { id: 'US-08 : dashboard-08.braze.com', value: 'US-08 : dashboard-08.braze.com' },
        { id: 'US-09 : dashboard-09.braze.com', value: 'US-09 : dashboard-09.braze.com' },
        { id: 'EU-01 : dashboard-01.braze.eu', value: 'EU-01 : dashboard-01.braze.eu' },
        { id: 'EU-02 : dashboard-02.braze.eu', value: 'EU-02 : dashboard-02.braze.eu' },
      ],
    },
  },
  appId: {
    description: `The app identifier used to reference specific Apps in requests made to the Braze API. Created under Developer Console in the Braze Dashboard.`,
    renderComponent: TextField,
    componentProps: {
      label: 'App ID',
      type: 'text',
    },
  },
  useJitsuAnonymousIdAlias: {
    description: `Use <code>anonymousId</code> as an alias for identified and anonymous profiles. Enables support for anonymous (alias-only) profiles.`,
    renderComponent: Switch,
    componentProps: {
      label: 'Use Anonymous Id Alias',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
  sendPageEvents: {
    description: `Send <code>page</code> and <code>screen</code> events as Braze Custom Events`,
    renderComponent: Switch,
    componentProps: {
      label: 'Send Page Events',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
}

export const BrazeCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={brazeCredentialsConfig} />
)
