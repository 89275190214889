import { GoogleSheetsProvider } from './SheetsContext'
import ConfigSheet from './Sheet'
import { useTenantsData } from 'redux/state/tenants'
import { useEnvironmentsData } from 'redux/state/environments'
import { useParams } from 'react-router-dom'

const ConfigSheetContainer = () => {
  const slug = useParams<{ slug: string }>().slug

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  if (!currentTenant) throw new Error('No current tenant')
  if (!currentStore) throw new Error('currentStore is undefined')
  if (!selectedEnvironment) throw new Error('No selected environment')

  return (
    <GoogleSheetsProvider
      clientOptions={{
        tenantId: currentTenant.id,
        storeId: currentStore.id,
        environment: selectedEnvironment,
      }}
    >
      <ConfigSheet slug={slug} />
    </GoogleSheetsProvider>
  )
}

export default ConfigSheetContainer
