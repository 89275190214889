import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Spinner, TabBar } from '@chordco/component-library'
import { useAnalyticsTabsHelper, useExperiments } from 'hooks'
import { useLookerData } from 'redux/state/looker'
import { Header } from '../common'
import { BrowserWarning } from './BrowserWarning'
import { useIsLoading } from 'redux/utils'
import { getAudienceTabs } from './tabs'
import PrebuiltAudiences from './audiences/PrebuiltAudiences'
import { SharedAudiences } from './audiences/SharedAudiences'
import { AddNewAudience } from './audiences/AddNewAudience'
import { Plus } from '@chordco/component-library/components/icons/standardIcons/Plus'
import { ProvisionAudiencesForm } from 'components/provisioning/ProvisionAudiencesForm'
import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'
import useUserRole from 'redux/hooks/useUserRole'
import audiencesHelp from 'help/audiences.md'
import waitFor from 'utils/delay'
import { AvailableUserAudience } from 'types'
import { useRefreshCensusAudiences } from 'components/looker/useRefreshCensusAudiences'
import { SyncedAudiences } from './audiences/SyncedAudiences'
import EmptyAudiences from 'components/settings/integrations/requests/EmptyAudiences'

export const Audiences: React.FC = () => {
  const myAudiencesTreatment = useExperiments('hub_my_audiences_nav_link')
  const audiencesEnabled = myAudiencesTreatment?.variation === 'on'

  const { addNotification } = useNotifications()

  const { refresh } = useRefreshCensusAudiences()

  const history = useHistory()
  const role = useUserRole()

  const [showProvisioningForm, setShowProvisioningForm] = useState(false)

  const {
    getAvailableAudiences,
    getSharedAudiences,
    state: { availableAudiences, availableSharedAudiences },
  } = useLookerData()

  const tabs = useMemo(() => getAudienceTabs(), [])

  const [selectedTabId, setSelectedTabId] = useAnalyticsTabsHelper(tabs)

  const { isSharedAudiences, isPrebuiltAudiences, isSyncedAudiences } = useMemo(() => {
    const path = history.location.pathname
    return {
      isSharedAudiences: path.includes('shared-audiences'),
      isPrebuiltAudiences: path.includes('prebuilt-audiences'),
      isSyncedAudiences: path.includes('audience-destinations'),
    }
  }, [history.location.pathname])

  const isLoading = useIsLoading('getAvailableAudiences', 'getSharedAudiences')

  useEffect(() => {
    const fetchAudiences = async () => {
      // always fetch shared audiences as they are used in multiple tabs
      if (getSharedAudiences) await getSharedAudiences()

      if (isPrebuiltAudiences && getAvailableAudiences) await getAvailableAudiences()
    }

    if (audiencesEnabled) fetchAudiences()
  }, [isPrebuiltAudiences, isSharedAudiences, audiencesEnabled])

  const notifyAudienceEvent = async (message: string) => {
    const id = generateUUID() as string
    addNotification(
      {
        id,
        type: 'success',
        message: `${message}. Please wait while we refresh Audiences...`,
      },
      2000
    )

    // Add delay to workaround issues where the Looker SDK fires the save or delete events too
    // quickly. This is also synchronized with the notification duration above for a better user
    // experience.
    await waitFor(2000)
  }

  const handleDeleteSharedAudience = async (audience: AvailableUserAudience) => {
    await notifyAudienceEvent('Audience deleted successfully')

    await refresh({
      audienceName: audience.label,
      audienceId: audience.id,
    })

    if (getSharedAudiences) {
      await getSharedAudiences()
      history.push('/audiences/shared-audiences')
    }
  }

  const handleSavedSharedAudience = async (audience: AvailableUserAudience) => {
    await notifyAudienceEvent('Audience saved successfully')

    await refresh({
      audienceName: audience.label,
      audienceId: audience.id,
    })

    if (getSharedAudiences) {
      await getSharedAudiences()
      history.push(`/audiences/shared-audiences/${audience.id}`)
    }
  }

  const handleProvisionAudiences = () => {
    setShowProvisioningForm(false)
  }

  const subtitle = isPrebuiltAudiences
    ? 'Select a prebuilt audience to customize or activate'
    : isSharedAudiences
    ? 'View all activated audience segments in your organization'
    : isSyncedAudiences
    ? 'Edit or update activated and synced audience segments'
    : ''

  return (
    <>
      <Header
        title="Audiences"
        hideBottomBorder={false}
        helpFile={audiencesHelp}
        subtitle={subtitle}
      >
        <BrowserWarning />

        {role === 'superuser' ? (
          <ProvisionAudiencesButton
            icon={Plus}
            variant="tertiary"
            onClick={() => setShowProvisioningForm(true)}
          >
            Configure
          </ProvisionAudiencesButton>
        ) : null}
      </Header>

      {selectedTabId && (
        <TabBar
          tabs={tabs}
          selectedId={selectedTabId}
          setSelectedId={setSelectedTabId}
          resetOnInvalidSelection
          location="Audiences"
        />
      )}

      <EmbedContainer>
        {!audiencesEnabled && <EmptyAudiences />}

        {audiencesEnabled && (
          <>
            {isLoading && <Spinner scale={30} />}

            {!isLoading && (
              <>
                {isPrebuiltAudiences && (
                  <PrebuiltAudiences audiences={availableAudiences.audiences} />
                )}

                {isSyncedAudiences && <SyncedAudiences />}

                {isSharedAudiences && (
                  <>
                    {availableSharedAudiences.audiences.length > 0 ? (
                      <SharedAudiences
                        sharedAudiences={availableSharedAudiences.audiences}
                        onDeletedAudience={handleDeleteSharedAudience}
                        onSavedAudience={handleSavedSharedAudience}
                      />
                    ) : (
                      <AddNewAudience />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </EmbedContainer>

      {showProvisioningForm && (
        <ProvisionAudiencesForm
          onClose={handleProvisionAudiences}
          tenantAudiences={availableAudiences.audiences}
        />
      )}
    </>
  )
}

const EmbedContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ProvisionAudiencesButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 100px; // move slightly to the left of the help button sitting in the top right corner
`
