import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './redux/store'
import 'simplebar/dist/simplebar.min.css'
import './global.css'
import './@chordco/component-library/theme/variables/global.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ErrorBoundary from './components/common/ErrorBoundary'
import { EnvVarConfig } from 'utils/envVarConfig'
import { SplitProvider } from './hooks/useSplit'
import { ThemeSwitcherProvider } from 'themes/ThemeSwitcherProvider'

const environment = process.env.NODE_ENV

Sentry.init({
  dsn: EnvVarConfig.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  // eslint-disable-next-line no-undef
  environment: environment,
  enabled: environment === 'production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  ignoreErrors: [
    /ResizeObserver loop completed with undelivered notifications/,
  ],
})

async function render() {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <SplitProvider>
          <ThemeSwitcherProvider>
            <BrowserRouter>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </BrowserRouter>
          </ThemeSwitcherProvider>
        </SplitProvider>
      </Provider>
    </React.StrictMode>
  )
}

render()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
