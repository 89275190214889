import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'
import { AvailableUserAudience } from 'types'
import { usePublishAudience } from 'components/looker/usePublishAudience'

const { AlertWarning } = standardIcons

type PublishConfirmationModalProps = {
  name: string
  description: string
  slug: string
  onCancel: () => void
  onPublish: (audience: AvailableUserAudience) => void
}

export const PublishConfirmationModal: React.FC<PublishConfirmationModalProps> = ({
  name,
  description,
  slug,
  onCancel,
  onPublish,
}) => {
  const { publish, isLoading } = usePublishAudience({ onPublish })

  const handlePublish = async () => {
    const audienceData = {
      audienceName: name,
      audienceDescription: description,
      audienceSlug: slug,
    }

    await publish(audienceData, true)
  }

  return (
    <AlertModal
      onClose={onCancel}
      icon={AlertWarning}
      content={`An audience with the name '${name}' already exists.`}
      subContent="You can overwrite the existing audience. Or simply cancel and create your audience under a different name."
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onCancel,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Yes, overwrite',
          onClick: handlePublish,
          purpose: 'destructive',
          isLoading: isLoading,
          disabled: false,
        },
      ]}
    />
  )
}
