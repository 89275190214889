import React, { useEffect } from 'react'
import { Button, Container, Sheet, standardIcons, Spinner } from '@chordco/component-library'
import { FormInput, Bottom, Form, FormDropdown } from '../common'
import { useForm } from 'hooks'
import { useAuthData } from 'redux/state/auth'
import { useIsLoading } from 'redux/utils'
import { useTenantsData } from 'redux/state/tenants'
import styled from 'styled-components'
import { useRolePermissionDescriptionsData } from 'redux/state/rolePermissionDescriptions'
import { SeatLimitReachedWarning, SeatLimitIncreaseRequest } from '../Tenant/SeatLimitBanners'
import { LoadingContainer } from 'components/settings/integrations/requests/Common'
import { useTenantSeatLimitHelper } from 'hooks/useTenantSeatLimithelper'

type Props = {
  onClose: () => void
}

const invitationUrl = `${window.location.origin}/authenticate`

export const InviteUser: React.FC<Props> = ({ onClose }) => {
  const { inviteUser, state } = useAuthData()

  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')

  const {
    state: { currentTenant },
    createTenantConfigurationServiceRequest,
  } = useTenantsData()

  if (!currentTenant) throw new Error('Current Tenant not found!')

  const isCurrentUserAdmin = state.user && state.user.role !== 'no_role'

  const { Plus } = standardIcons

  const { state: roles, getRolePermissionDescriptions } = useRolePermissionDescriptionsData()

  const submit = async ({ email, role }) => {
    await inviteUser({
      email,
      invitationUrl,
      role: isCurrentUserAdmin ? role : 'no_role',
    })
    onClose()
  }

  const {
    numberOfSeatsLeft,
    hasTenantReachedSeatLimit,
    hasTenantRequestedSeatLimitIncrease,
    tenantSeatCountBelowLimit,
  } = useTenantSeatLimitHelper()

  const requestMoreSeats = () => {
    createTenantConfigurationServiceRequest({
      tenantId: currentTenant.id,
      requestType: 'User Seat Limit Increase',
      userName: `${user.firstName} ${user.lastName}`,
      userEmail: user.email ?? 'n/a',
    })
  }

  const numberOfRemainingSeats = () => Math.max(0, numberOfSeatsLeft)

  const { fields, onSubmit } = useForm(
    {
      email: {
        label: 'Email',
        type: 'email',
        initialValue: '',
        required: !hasTenantRequestedSeatLimitIncrease,
      },
      role: {
        label: 'Role',
        type: 'array',
        initialValue: 'admin',
        required: false,
      },
    },
    submit
  )

  const isBusy = useIsLoading(
    'createTenantConfigurationServiceRequest',
    'inviteUser',
    'getRolePermissionDescriptions',
    'getUserTenants'
  )

  useEffect(() => {
    if (!roles.rolePermissionDescriptions.length) {
      getRolePermissionDescriptions(currentTenant.id)
    }
  }, [])

  return (
    <Sheet
      title="Invite New User"
      name="Invite User Sheet"
      location="Team"
      onClose={onClose}
      customWidth=""
    >
      <SheetContent>
        <Form onSubmit={onSubmit} noValidate>
          <SheetSubTitle>Your team has {numberOfRemainingSeats()} seats left</SheetSubTitle>
          {hasTenantReachedSeatLimit && <SeatLimitReachedWarning />}
          {hasTenantRequestedSeatLimitIncrease && <SeatLimitIncreaseRequest />}
          <FormInput field={fields.email} customWidth="100%" />
          {isCurrentUserAdmin && roles.rolePermissionDescriptions.length > 0 && (
            <RoleDescription>
              <FormDropdown
                field={{ ...fields.role }}
                options={roles.rolePermissionDescriptions.map(permission => ({
                  id: permission.role,
                  label: permission.label,
                }))}
                customWidth="100%"
              />
            </RoleDescription>
          )}

          {isBusy && (
            <LoadingContainer>
              <Spinner scale={30} />
            </LoadingContainer>
          )}

          {!isBusy && roles.rolePermissionDescriptions && isCurrentUserAdmin && (
            <Container>
              <RolePermissionHeading>Role Permissions:</RolePermissionHeading>
              <RolePermissionContent>
                {roles.rolePermissionDescriptions
                  .filter(role => role.role === fields.role.value)
                  .map(RolePermission =>
                    RolePermission.permission.map(permission => (
                      <RolePermissionDescription key={permission}>
                        <StyledList>
                          <li>{permission}</li>
                        </StyledList>
                      </RolePermissionDescription>
                    ))
                  )}
              </RolePermissionContent>
            </Container>
          )}
          <Bottom>
            {isBusy && (
              <LoadingContainer>
                <Spinner scale={30} />
              </LoadingContainer>
            )}
            {!isBusy && hasTenantReachedSeatLimit && (
              <Button
                onClick={requestMoreSeats}
                isLoading={isBusy}
                disabled={isBusy}
                purpose="primary"
                centreContent={true}
              >
                Request More Seats
              </Button>
            )}
            {hasTenantRequestedSeatLimitIncrease && (
              <Button
                name="Invite User"
                location="Invite User Sheet"
                icon={Plus}
                disabled={true}
                purpose="primary"
                centreContent={true}
              >
                Invite User
              </Button>
            )}
            {tenantSeatCountBelowLimit && (
              <Button
                name="Invite User"
                location="Invite User Sheet"
                type="submit"
                icon={Plus}
                isLoading={isBusy}
                disabled={isBusy}
                purpose="primary"
                centreContent={true}
              >
                Invite User
              </Button>
            )}
          </Bottom>
        </Form>
      </SheetContent>
    </Sheet>
  )
}
const RolePermissionContent = styled.div`
  margin-top: 5px;
`

const RoleDescription = styled.div`
  color: ${p => p.theme.ContentSecondary};
  min-width: 400px;
`
const RolePermissionHeading = styled.div`
  font-weight: 600;
  color: ${p => p.theme.ContentSecondary};
  margin-top: 12px;
`
const RolePermissionDescription = styled.div`
  color: ${p => p.theme.ContentSecondary};
`

const SheetContent = styled.div`
  margin-left: 10px;
`

const SheetSubTitle = styled.div`
  color: ${p => p.theme.ContentSecondary};
  margin-top: 12px;
`

const StyledList = styled.ul`
  padding: 0;
  margin-top: 5px;
  margin-left: 15px;
`
