import { TextField, Select } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import KeyValueList from 'components/chord-cdp/common/KeyValueList'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const mongodbCredentialsConfig: CredentialsConfig = {
  protocol: {
    description: `MongoDB protocol. <code>mongodb</code> or <code>mongodb+srv</code>. For Atlas use <code>mongodb+srv</code>`,
    renderComponent: Select,
    componentProps: {
      label: 'Protocol',
      defaultValue: 'mongodb',
      fullWidth: true,
      required: true,
      options: [
        { id: 'mongodb', value: 'Mongo DB' },
        { id: 'mongodb+srv', value: 'Mongo DB + SRV' },
      ],
    },
  },
  hosts: {
    description: `MongoDB hosts with port (e.g. <code>localhost:27017</code>). One on each line.`,
    renderComponent: TextField,
    componentProps: {
      label: 'MongoDB Hosts',
      type: 'text',
      multiline: true,
      rows: 4,
    },
  },
  username: {
    description: `MongoDB username`,
    renderComponent: TextField,
    componentProps: {
      label: 'Username',
      type: 'text',
      required: true,
    },
  },
  password: {
    description: `MongoDB password`,
    renderComponent: TextField,
    componentProps: {
      label: 'Password',
      type: 'password',
      required: true,
    },
  },
  database: {
    description: `MongoDB database`,
    renderComponent: TextField,
    componentProps: {
      label: 'Database',
      type: 'text',
      required: true,
    },
  },
  collection: {
    description: `MongoDB collection`,
    renderComponent: TextField,
    componentProps: {
      label: 'Collection',
      type: 'text',
      required: true,
    },
  },
  parameters: {
    description: 'Additional MongoDB connection options.',
    renderComponent: KeyValueList,
    componentProps: {
      label: 'Options',
    },
  },
}

export const MongodbCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={mongodbCredentialsConfig} />
)
