import React from 'react'
import styled from 'styled-components'
import { Button, standardIcons } from '@chordco/component-library'

type DestinationsToolbarProps = {
  refreshAndFetch: () => void
  toggleCreateSyncs: () => void
  toggleDestinationBrowser: () => void
  isBusy: boolean
  canAddDestinations: boolean
}

export const DestinationsToolbar: React.FC<DestinationsToolbarProps> = ({
  refreshAndFetch,
  toggleCreateSyncs,
  toggleDestinationBrowser,
  isBusy,
  canAddDestinations,
}: DestinationsToolbarProps) => {
  return (
    <Header>
      <MySourcesContainer>
        <Button
          type="button"
          purpose="tertiary"
          onClick={refreshAndFetch}
          icon={standardIcons.Refresh}
          name="Refresh"
          location="Data Activations"
          disabled={isBusy}
        >
          Refresh
        </Button>
      </MySourcesContainer>

      <ButtonsContainer>
        <Button
          type="button"
          onClick={toggleDestinationBrowser}
          icon={standardIcons.Plus}
          name="Add Destination"
          location="Data Activations"
          disabled={!canAddDestinations}
          tooltip={!canAddDestinations ? 'Please contact an admin to add destinations' : undefined}
          tooltipDirection="left"
        >
          Add Destination
        </Button>

        <Button
          type="button"
          onClick={toggleCreateSyncs}
          icon={standardIcons.Sync}
          variant="outlined"
          name="Manage Syncs"
          location="Data Activations"
        >
          Manage Syncs
        </Button>
      </ButtonsContainer>
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`

const MySourcesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`
