import React from 'react'
import { H3, Label, Tooltip } from '@chordco/component-library'
import styled from 'styled-components'
import { Box, FormControl, IconButton, MenuItem, Select } from '@mui/material'
import { DataSettingsSchedulingMonths, DataSettingsFiscalYearPeriods } from 'types'
import { InfoOutlined } from '@mui/icons-material'
import useUserRole from 'redux/hooks/useUserRole'

type FiscalYearAndPeriodPanelProps = {
  fiscalYear: string
  fiscalPeriod: string
  setFiscalPeriodAndFiscalYear: (fiscalPeriod: string, fiscalYear: string) => void
}

const months: DataSettingsSchedulingMonths[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const fiscalYearPeriods: DataSettingsFiscalYearPeriods[] = [
  'Calendar (Gregorian)',
  '4-4-5',
  '4-5-4',
  '5-4-4',
]

export const FiscalYearAndPeriodPanel: React.FC<FiscalYearAndPeriodPanelProps> = ({
  fiscalYear,
  fiscalPeriod,
  setFiscalPeriodAndFiscalYear,
}) => {
  const handleFiscalYearChange = event => {
    setFiscalPeriodAndFiscalYear(fiscalPeriod, event.target.value)
  }

  const handleFiscalPeriodChange = event => {
    if (event.target.value === 'Calendar (Gregorian)') {
      setFiscalPeriodAndFiscalYear(event.target.value, 'January')
    } else {
      setFiscalPeriodAndFiscalYear(event.target.value, fiscalYear)
    }
  }

  const role = useUserRole()
  const disableMemberUser = role === 'no_role'
  const disableYearStart = fiscalPeriod === 'Calendar (Gregorian)'

  return (
    <>
      <SchedulingContainer>
        <FiscalYearContainer>
          <TitleContainer>
            <H3>Set Fiscal Year Start and Fiscal Periods</H3>
            <TooltipContainer>
              <Tooltip
                text={`Change or update the starting month for your organization's fiscal year and set the relevant fiscal period for reporting purposes. The Chord default definition of a year is January through December.`}
                direction="right"
                customWidth="400px"
              >
                <IconButton size="small">
                  <InfoOutlined fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </TooltipContainer>
          </TitleContainer>
          <Label> Fiscal Year Start Date </Label>
          <FormControl
            sx={{ minWidth: 350 }}
            size="small"
            disabled={disableMemberUser || disableYearStart}
          >
            <Select id="year_start-picker" value={fiscalYear} onChange={handleFiscalYearChange}>
              {months.map(month => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FiscalYearContainer>
        <FiscalPeriodContainer>
          <Label> Fiscal Periods </Label>
          <FormControl sx={{ minWidth: 350 }} size="small" disabled={disableMemberUser}>
            <Select
              id="fiscal-period-picker"
              value={fiscalPeriod}
              onChange={handleFiscalPeriodChange}
            >
              {fiscalYearPeriods.map(fiscalYear => (
                <MenuItem key={fiscalYear} value={fiscalYear}>
                  {fiscalYear}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FiscalPeriodContainer>
      </SchedulingContainer>
      <BoxContainer>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
      </BoxContainer>
    </>
  )
}

const SchedulingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  padding-top: 25px;
`

const TooltipContainer = styled.div`
  display: absolute;
  top: 0px;
  left: 200px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding-bottom: 20px;
  color: ${p => p.theme.ContentPrimary};
`

const FiscalYearContainer = styled.div`
  flex-grow: 1;
  color: ${p => p.theme.ContentTertiary};
`

const FiscalPeriodContainer = styled.div`
  align-self: flex-end;
  padding-top: 20px;
  color: ${p => p.theme.ContentTertiary};
`
const BoxContainer = styled.div`
  padding-top: 40px;
  margin-left: 10px;
`
