import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import { AvailableUserAudience, PublishAudienceResponse } from 'types'
import { PayloadAction } from '@reduxjs/toolkit'

export interface PublishAudienceData {
  audienceName: string
  audienceDescription: string
  audienceSlug: string
}

export interface PublishAudienceCallbacks {
  onPublish: (audience: AvailableUserAudience) => void
  onNameAlreadyTaken?: (data: PublishAudienceData) => void
}

export const usePublishAudience = ({ onPublish, onNameAlreadyTaken }: PublishAudienceCallbacks) => {
  const {
    state: { availableSharedAudiences },
    publishAudience,
  } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)

  const isNameTaken = (name: string) =>
    availableSharedAudiences?.audiences.some(audience => audience.label === name)

  const publish = async (data: PublishAudienceData, replaceExisting = false) => {
    if (!publishAudience) return

    setIsLoading(true)

    try {
      if (!replaceExisting && isNameTaken(data.audienceName)) {
        onNameAlreadyTaken?.(data)
        return
      }

      const response = (await publishAudience(
        data.audienceName,
        data.audienceDescription,
        data.audienceSlug,
        replaceExisting
      )) as PayloadAction<{
        data: PublishAudienceResponse
      }>

      const audience = response.payload.data.audience
      if (audience) onPublish(audience)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Publish audience error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return { publish, isLoading }
}
