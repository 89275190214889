import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { AvailableUserAudience } from 'types'
import { SelectChangeEvent } from '@mui/material'
import { Button, standardIcons } from '@chordco/component-library'
import { AudienceFolderDropdown } from './AudienceFolderDropdown'
import { UserAudienceEmbed } from './UserAudienceEmbed'
import { useRefreshCensusAudiences } from 'components/looker/useRefreshCensusAudiences'

type SharedAudiencesProps = {
  sharedAudiences: AvailableUserAudience[]
  onDeletedAudience: (audience: AvailableUserAudience) => void
  onSavedAudience: (audience: AvailableUserAudience) => void
}

export const SharedAudiences: React.FC<SharedAudiencesProps> = ({
  sharedAudiences,
  onDeletedAudience,
  onSavedAudience,
}) => {
  const { refresh } = useRefreshCensusAudiences()

  const history = useHistory()
  const slug = history.location.pathname.split('/').pop()

  const selectedOption = sharedAudiences.find(d => d.slug === slug) || sharedAudiences[0]

  if (!selectedOption) return null

  const handleSelectChanged = (event: SelectChangeEvent<string>) => {
    const report = sharedAudiences.find(d => d.id === event.target.value)
    if (report) {
      history.push(`/audiences/shared-audiences/${report.slug}`)
    }
  }

  const handleDeletedAudience = async () => {
    onDeletedAudience(selectedOption)
  }

  const handleSavedAudience = async () => {
    onSavedAudience(selectedOption)
  }

  const handleActivateAudience = async () => {
    if (selectedOption) {
      await refresh({
        audienceName: selectedOption.name,
        audienceId: selectedOption.id,
      })
    }

    history.push('/audiences/audience-destinations')
  }

  return (
    <Wrapper>
      <Top>
        <AudienceFolderDropdown
          title="Shared Audiences"
          audiences={sharedAudiences}
          onChange={handleSelectChanged}
          defaultValue={selectedOption.id}
        />

        <RightButtons>
          <Button
            purpose="primary"
            icon={standardIcons.DataActivations}
            onClick={handleActivateAudience}
            name={`Sync Audience ${selectedOption.label}`}
            location="Audiences"
          >
            Sync Audience
          </Button>

          <Button
            purpose="secondary"
            icon={standardIcons.Template}
            onClick={() => history.push('/audiences/prebuilt-audiences')}
            name="Browse Templates"
            location="Audiences"
          >
            Browse Templates
          </Button>
        </RightButtons>
      </Top>
      <EmbedContainer>
        <UserAudienceEmbed
          slug={selectedOption.slug}
          onDeleteAudience={handleDeletedAudience}
          onSavedAudience={handleSavedAudience}
        />
      </EmbedContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  height: 100vh;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const RightButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const EmbedContainer = styled.div`
  border-radius: 0 0 0 ${p => p.theme.PageRadius};
  border-top: 1px solid ${p => p.theme.BorderOptimal};
  height: 100%;
`
