import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const attentiveCredentialsConfig: CredentialsConfig = {
  apiKey: {
    description:
      "A <a href='https://docs.attentive.com/pages/create-and-manage-custom-apps/'>custom app</a> is required to generate an API Key",
    renderComponent: TextField,
    componentProps: {
      label: 'Attentive API Key',
      type: 'text',
      required: true,
    },
  },
}

export const AttentiveCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={attentiveCredentialsConfig} />
)
