import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import { ActivateAudienceResponse } from 'types'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

interface RefreshCensusAudiencesData {
  audienceName: string
  audienceId: string
}

export const useRefreshCensusAudiences = () => {
  const { activateAudience } = useLookerData()
  const { trackEvent } = useMixpanel()
  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const refresh = async ({ audienceName, audienceId }: RefreshCensusAudiencesData) => {
    if (!activateAudience) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await activateAudience({ audienceId })) as PayloadAction<{
        data: ActivateAudienceResponse
      }>

      if (response.payload.data?.status === 'success') {
        setHasSucceeded(true)
        trackEvent(MixpanelEventType.AudienceActivated, {
          'Audience Name': audienceName,
          'Page Name': 'Audiences',
        })
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { refresh, isLoading, hasSucceeded, hasFailed }
}
