import { useRef, useEffect, useState } from 'react'
import { LookEvent, LookerEmbedSDK } from '@looker/embed-sdk'
import { useTheme } from '@chordco/component-library'
import { CloseX } from '@chordco/component-library/components/icons/standardIcons/CloseX'
import { EmbedContainer } from './EmbedContainer'
import { ErrorContainer } from './ErrorContainer'
import { EnvVarConfig } from 'utils/envVarConfig'
import useEmbedUserUrl from './useEmbedUserUrl'
import { Loading } from 'components/common'

export interface LookEmbedProps {
  lookId: string
  onDeletedLook: (event: LookEvent) => void
  onSavedLook: (event: LookEvent) => void
}

export const UserLookEmbed = ({
  lookId,
  onDeletedLook,
  onSavedLook,
}: LookEmbedProps) => {
  const theme = useTheme()

  const ref = useRef<HTMLDivElement>(null)

  const [error, setError] = useState<string | null>(null)

  const lookerHost = EnvVarConfig.REACT_APP_LOOKER_HOST

  const { embedUserUrls, isLoading } = useEmbedUserUrl(lookId, 'look')

  useEffect(() => {
    const el = ref.current
    if (el && lookerHost && lookId) {
      el.innerHTML = ''

      LookerEmbedSDK.createLookWithUrl(embedUserUrls[lookId])
        .withApiHost(lookerHost)
        .withNext()
        .withDialogScroll(true)
        .withDynamicIFrameHeight(true)
        .withScrollMonitor(true)
        .withAllowAttr('fullscreen')
        .appendTo(el)
        .on('look:delete:complete', onDeletedLook)
        .on('look:save:complete', onSavedLook)
        .build()
        .connect()
        .catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.error('Error loading Looker user look:', error)
          setError(
            'We are unable to load the user look. Please try again later.'
          )
        })
    }
  }, [lookId, lookerHost, embedUserUrls])

  return (
    <>
      {error && (
        <ErrorContainer>
          <div>{error}</div>
          <span onClick={() => setError(null)}>
            <CloseX fill={theme.GREY_80} />
          </span>
        </ErrorContainer>
      )}
      {isLoading ? <Loading /> : <EmbedContainer id="embedcontainer" ref={ref} />}
    </>
  )
}
