import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DataAudiences from 'assets/DataAudiences.svg'
import { Button } from '@chordco/component-library'
import AccessRequestConfirmed from './AccessRequestConfirmed'
import { CenteredContent, ImageContainer, StyleBody, StyledH1 } from './Common'
import { useNotion } from 'hooks/useNotion'
import { useAuthData } from 'redux/state/auth'
import { useTenantsData } from 'redux/state/tenants'
import { Loading } from 'components/common'

export default function EmptyAudiences() {
  const {
    state: { currentTenant },
  } = useTenantsData()

  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')
  if (!currentTenant) throw new Error('No current tenant')

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isFetching, setIsFetching] = useState(true)

  const { createFeatureAccessRequest, fetchFeatureAccessRequests } = useNotion()

  const handleClick = async () => {
    try {
      await createFeatureAccessRequest({
        tenantId: currentTenant.id,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email ?? 'n/a',
        feature: 'data_audiences',
      })

      setShowConfirmation(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating feature access request', error)
    }
  }

  useEffect(() => {
    const fetchAccessRequest = async () => {
      try {
        setIsFetching(true)

        const response = await fetchFeatureAccessRequests(currentTenant.id, 'data_audiences')

        if (response.results.length > 0) {
          setShowConfirmation(true)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching feature access requests', error)
      } finally {
        setIsFetching(false)
      }
    }

    fetchAccessRequest()
  }, [fetchFeatureAccessRequests, currentTenant.id])

  if (isFetching) {
    return <Loading hideMask opacity={1} />
  }

  return (
    <Wrapper>
      <CenteredContent gap={48}>
        <ImageContainer>
          <img src={DataAudiences} alt="Audiences" />
        </ImageContainer>

        <TextCtaContainer>
          <StyledH1>Build and Leverage Audiences Effortlessly</StyledH1>
          <StyleBody>
            Create and manage tailored audience segments effortlessly with our pre-built options and
            upcoming audience builder tools to refine your marketing strategy.
          </StyleBody>

          {showConfirmation && <AccessRequestConfirmed />}

          {!showConfirmation && (
            <Button onClick={handleClick} name="Request Early Access" location="Audiences">
              Request Early Access
            </Button>
          )}
        </TextCtaContainer>
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 24px;
`

const TextCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
