import React from 'react'
import styled from 'styled-components'
import { Col, TableHeader } from 'components/common/table'
import { CensusSync } from 'api/census/interfaces'
import { SyncRow } from './SyncRow'
import { Paginator } from './Paginator'

type Props = {
  syncs: CensusSync[]
  onRunSync: (sync: CensusSync) => void
  onCancelSync: (sync: CensusSync) => void
  onDeleteSync: (sync: CensusSync) => void
  onEditSync: (sync: CensusSync) => void
  onUpdateSyncSchedule: (sync: CensusSync) => void
  onPrevPage: () => void
  onNextPage: () => void
  showNextPageButton: boolean
  showPreviousPageButton: boolean
  canUpdateSync: boolean
}

export const SyncTable: React.FC<Props> = ({
  syncs,
  onRunSync,
  onCancelSync,
  onDeleteSync,
  onEditSync,
  onUpdateSyncSchedule,
  onPrevPage,
  onNextPage,
  showNextPageButton,
  showPreviousPageButton,
  canUpdateSync,
}) => {
  const paginatorProps = {
    onPrevPage: onPrevPage,
    onNextPage: onNextPage,
    showNextPage: showNextPageButton,
    showPrevPage: showPreviousPageButton,
  }

  return (
    <>
      <TableWrapper>
        <StyledTableHeader>
          <Col flex={1}>Status</Col>
          <Col flex={1}>Label</Col>
          <Col flex={1}>Source</Col>
          <Col flex={1}>Destination</Col>
          <Col flex={1}>Last Run</Col>
          <Col flex={1}>Run Schedule</Col>
          <Col align="center" flex={1}>
            Actions
          </Col>
        </StyledTableHeader>

        <TableContent>
          {syncs.map(sync => (
            <SyncRow
              key={sync.id}
              sync={sync}
              onRunSync={onRunSync}
              onCancelSync={onCancelSync}
              onDeleteSync={onDeleteSync}
              onEditSync={onEditSync}
              onUpdateSyncSchedule={onUpdateSyncSchedule}
              canUpdateSync={canUpdateSync}
            />
          ))}
        </TableContent>
      </TableWrapper>

      <Paginator {...paginatorProps} />
    </>
  )
}

const TableWrapper = styled.div`
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};
`

const StyledTableHeader = styled(TableHeader)`
  border-top-right-radius: ${p => p.theme.CardMediumRadius};
  border-top-left-radius: ${p => p.theme.CardMediumRadius};
  overflow: hidden;
`

const TableContent = styled.div`
  border-bottom-right-radius: ${p => p.theme.CardMediumRadius};
  border-bottom-left-radius: ${p => p.theme.CardMediumRadius};
`
