import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const gtmDeviceDestinationCredentialsConfig: CredentialsConfig = {
  containerId: {
    description: 'The Container ID uniquely identifies the GTM Container.',
    renderComponent: TextField,
    componentProps: {
      label: 'Container ID',
      type: 'text',
      required: true,
    },
  },
  dataLayerName: {
    description: 'The name of the data layer variable.',
    renderComponent: TextField,
    componentProps: {
      label: 'Data Layer Name',
      type: 'text',
      required: true,
    },
  },
}

export const GtmDeviceDestinationCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={gtmDeviceDestinationCredentialsConfig} />
)
